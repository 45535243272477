import {
  faFacebook,
  faInstagram,
  faTwitter,
  faYoutube
} from '@fortawesome/free-brands-svg-icons';
import { route } from '../../../route';

export const items = [
  {
    title: 'Início',
    to: route('home')
  },
  {
    title: 'Notícias de Concursos',
    to: route('news-index')
  },
  {
    title: 'Editorias',
    to: route('editorials-index')
  },
  {
    title: 'Vídeos',
    to: 'https://www.youtube.com/@folhadirigida',
    props: {
      target: '_blank',
      rel: 'noopener noreferrer'
    }
  },
  {
    title: 'Qconcursos',
    to: 'https://www.qconcursos.com',
    props: {
      target: '_blank'
    }
  }
];

export const contests = [
  {
    title: 'Concursos Rio de Janeiro',
    to: 'https://folha.qconcursos.com/e/concursos-rio-de-janeiro-rj',
    props: {
      target: '_blank'
    }
  },
  {
    title: 'Concursos Abertos',
    to: 'https://folha.qconcursos.com/e/concursos-abertos',
    props: {
      target: '_blank'
    }
  },
  {
    title: 'Concursos Nordeste',
    to: 'https://folha.qconcursos.com/e/concursos-nordeste',
    props: {
      target: '_blank'
    }
  },
  {
    title: 'Concursos Previstos',
    to: 'https://folha.qconcursos.com/e/concursos-previstos',
    props: {
      target: '_blank'
    }
  },
  {
    title: 'Concursos Sudeste',
    to: 'https://folha.qconcursos.com/e/concursos-sudeste',
    props: {
      target: '_blank'
    }
  }
];

export const institutes = [
  {
    title: 'Concurso Público Caixa Econômica Federal',
    to: 'https://folha.qconcursos.com/n/concurso-caixa',
    props: {
      target: '_blank'
    }
  },
  {
    title: 'Concurso Público Ministério da Educação (MEC)',
    to: 'https://folha.qconcursos.com/e/ministerio-da-educacao',
    props: {
      target: '_blank'
    }
  },
  {
    title: 'Concurso Público DataPrev',
    to: 'https://folha.qconcursos.com/e/empresa-de-tecnologia-e-informacoes-da-previdencia',
    props: {
      target: '_blank'
    }
  },
  {
    title: 'Concurso IBGE',
    to: 'https://folha.qconcursos.com/e/instituto-brasileiro-de-geografia-e-estatistica',
    props: {
      target: '_blank'
    }
  },
  {
    title: 'Concurso Correios',
    to: 'https://folha.qconcursos.com/n/concurso-correios',
    props: {
      target: '_blank'
    }
  }
];

export const boards = [
  {
    title: 'IBFC',
    to: 'https://www.qconcursos.com/questoes-de-concursos/bancas/ibfc',
    props: {
      target: '_blank'
    }
  },
  {
    title: 'Vunesp',
    to: 'https://www.qconcursos.com/questoes-de-concursos/bancas/vunesp',
    props: {
      target: '_blank'
    }
  },
  {
    title: 'Cespe - Cebraspe',
    to: 'https://www.qconcursos.com/questoes-de-concursos/bancas/cespe-cebraspe',
    props: {
      target: '_blank'
    }
  },
  {
    title: 'Ibade',
    to: 'https://www.qconcursos.com/questoes-de-concursos/bancas/ibade',
    props: {
      target: '_blank'
    }
  },
  {
    title: 'Consulplan',
    to: 'https://www.qconcursos.com/questoes-de-concursos/bancas/consulplan',
    props: {
      target: '_blank'
    }
  }
];

export const subjects = [
  {
    title: 'Português',
    to: 'https://www.qconcursos.com/questoes-de-concursos/disciplinas/letras-portugues',
    props: {
      target: '_blank'
    }
  },
  {
    title: 'Matemática',
    to: 'https://www.qconcursos.com/questoes-de-concursos/disciplinas/matematica-matematica',
    props: {
      target: '_blank'
    }
  },
  {
    title: 'Interpretação de Textos',
    to: 'https://www.qconcursos.com/questoes-de-concursos/disciplinas/letras-portugues/interpretacao-de-textos/questoes',
    props: {
      target: '_blank'
    }
  },
  {
    title: 'Raciocínio Lógico',
    to: 'https://www.qconcursos.com/questoes-de-concursos/disciplinas/matematica-raciocinio-logico/fundamentos-de-logica/questoes',
    props: {
      target: '_blank'
    }
  },
  {
    title: 'Informática',
    to: 'https://www.qconcursos.com/questoes-de-concursos/disciplinas/tecnologia-da-informacao-nocoes-de-informatica',
    props: {
      target: '_blank'
    }
  }
];

export const social = [
  {
    title: 'Facebook',
    to: 'https://www.facebook.com/qconcursos.folha/',
    props: {
      target: '_blank',
      bg: '#547bbc',
      icon: faFacebook
    }
  },
  {
    title: 'X',
    to: 'https://x.com/qconcursosfolha',
    props: {
      target: '_blank',
      bg: '#78caee',
      icon: faTwitter
    }
  },
  {
    title: 'Youtube',
    to: 'https://www.youtube.com/folhadirigida',
    props: {
      target: '_blank',
      bg: 'red',
      icon: faYoutube
    }
  },
  {
    title: 'Instagram',
    to: 'https://www.instagram.com/qconcursos.folha/',
    props: {
      target: '_blank',
      bg: '#d93175',
      icon: faInstagram
    }
  }
];
