import { ComponentProps, useMemo } from 'react';
import { styled } from '@qcx/ui';

export interface LogoProps extends ComponentProps<typeof StyledSvg> {
  width?: number;
  height?: number;
}

const DEFAULT_WIDTH = 193;
const DEFAULT_HEIGHT = 40;

export const Logo = ({ width, height, ...props }: LogoProps) => {
  const calculatedWidth = useMemo(
    () =>
      width ??
      (height ? (height * DEFAULT_WIDTH) / DEFAULT_HEIGHT : DEFAULT_WIDTH),
    [height, width]
  );

  const calculatedHeight = useMemo(
    () =>
      height ??
      (width ? (width * DEFAULT_HEIGHT) / DEFAULT_WIDTH : DEFAULT_HEIGHT),
    [height, width]
  );

  return (
    <StyledSvg
      css={{
        w: calculatedWidth,
        h: calculatedHeight,
        ...props.css
      }}
      width={calculatedWidth}
      height={calculatedHeight}
      fill="none"
      viewBox="0 0 147 37"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        clipRule="evenodd"
        d="M50.0924 22.674L46.906 19.2317C48.7229 18.053 49.8944 15.9903 49.8815 13.4457C49.8815 9.40036 46.8795 6.54736 43.101 6.54736C39.3225 6.54736 36.3213 9.40036 36.3213 13.4457C36.3213 17.491 39.3628 20.3567 43.101 20.3567C43.7729 20.3567 44.4045 20.2766 44.9966 20.102L47.3268 22.674H50.0915H50.0924ZM47.6568 13.4457C47.6568 16.4996 45.7474 18.4413 43.1147 18.4413C40.482 18.4413 38.5726 16.4987 38.5726 13.4457C38.5726 10.3926 40.482 8.48998 43.1147 8.48998C45.7474 8.48998 47.6568 10.3917 47.6568 13.4457Z"
        fill="#1D1D1B"
        fillRule="evenodd"
      />
      <path
        d="M61.3619 12.8968C60.7697 10.6468 59.0977 9.34717 56.5173 9.34717C53.5024 9.34717 51.3564 11.5172 51.3564 14.8658C51.3564 18.2145 53.5024 20.3981 56.5173 20.3981C59.0849 20.3981 60.7697 18.9511 61.3619 16.8484H59.0179C58.6229 17.9471 57.8199 18.5364 56.5164 18.5364C54.7527 18.5364 53.594 17.2104 53.594 14.8667C53.594 12.523 54.7655 11.2098 56.5164 11.2098C57.8199 11.2098 58.6494 11.8664 59.0179 12.8977H61.3619V12.8968Z"
        fill="#1D1D1B"
      />
      <path
        clipRule="evenodd"
        d="M68.1032 20.3971C65.1011 20.3971 62.8369 18.2271 62.8369 14.8648C62.8369 11.5025 65.2065 9.34619 68.1948 9.34619C71.1832 9.34619 73.5527 11.5025 73.5527 14.8648C73.5527 18.2271 71.1172 20.3971 68.1022 20.3971H68.1032ZM68.1032 18.4818C69.7229 18.4818 71.3289 17.3031 71.3289 14.8657C71.3289 12.4284 69.7889 11.2761 68.1563 11.2761C66.5237 11.2761 65.0497 12.4411 65.0497 14.8657C65.0497 17.2904 66.4715 18.4818 68.1041 18.4818H68.1032Z"
        fill="#1D1D1B"
        fillRule="evenodd"
      />
      <path
        d="M80.5568 11.2625C82.1362 11.2625 83.1895 12.2802 83.1895 14.2365V20.2235H85.3482V13.9145C85.3482 10.9142 83.492 9.34717 81.0298 9.34717C79.7657 9.34717 78.607 9.8692 77.8966 10.7405V9.52178H75.7241V20.2235H77.8966V14.2365C77.8966 12.2675 78.9499 11.2625 80.5559 11.2625H80.5568Z"
        fill="#1D1D1B"
      />
      <path
        d="M92.5378 9.3471C95.1182 9.3471 96.7902 10.6458 97.3824 12.8967H95.0394C94.6709 11.8654 93.8413 11.2088 92.5378 11.2088C90.787 11.2088 89.6155 12.5348 89.6155 14.8657C89.6155 17.1967 90.7741 18.5354 92.5378 18.5354C93.8413 18.5354 94.6443 17.9461 95.0394 16.8475H97.3824C96.7902 18.9501 95.1045 20.3971 92.5378 20.3971C89.5229 20.3971 87.377 18.2271 87.377 14.8648C87.3898 11.5162 89.5229 9.34619 92.5378 9.34619V9.3471Z"
        fill="#1D1D1B"
      />
      <path
        d="M106.913 20.2231H109.099V9.52145H106.926V15.4684C106.926 17.4374 105.873 18.4424 104.267 18.4424C102.661 18.4424 101.634 17.4247 101.634 15.4684V9.50781H99.4756V15.8031C99.4756 18.8035 101.345 20.3705 103.807 20.3705C105.044 20.3705 106.23 19.8484 106.913 18.9371V20.2231Z"
        fill="#1D1D1B"
      />
      <path
        d="M114.141 20.2235H111.969V9.52178H114.141V11.0752C114.747 10.0038 115.8 9.34717 117.289 9.34717V11.6372H116.736C115.13 11.6372 114.142 12.3202 114.142 14.5839V20.2226L114.141 20.2235Z"
        fill="#1D1D1B"
      />
      <path
        d="M118.71 16.9417C118.817 18.8834 120.593 20.3976 123.108 20.3976C125.622 20.3976 127.136 19.0443 127.136 17.2363C127.07 15.0236 125.1 14.4697 123.426 13.9986C122.17 13.6448 121.081 13.3384 121.081 12.4143C121.081 11.6913 121.7 11.2093 122.859 11.2093C124.019 11.2093 124.742 11.8387 124.821 12.7763H126.993C126.874 10.6327 125.309 9.33398 122.926 9.33398C120.544 9.33398 118.885 10.7136 118.885 12.428C118.885 14.6935 120.921 15.2501 122.642 15.7193C123.902 16.0631 124.993 16.3614 124.993 17.25C124.993 17.9866 124.335 18.536 123.072 18.536C121.809 18.536 121.044 17.8129 120.965 16.9426H118.713L118.71 16.9417Z"
        fill="#1D1D1B"
      />
      <path
        clipRule="evenodd"
        d="M134.048 20.3971C131.047 20.3971 128.783 18.2271 128.783 14.8648C128.783 11.5025 131.152 9.34619 134.141 9.34619C137.129 9.34619 139.499 11.5025 139.499 14.8648C139.499 18.2271 137.063 20.3971 134.047 20.3971H134.048ZM134.048 18.4818C135.669 18.4818 137.275 17.3031 137.275 14.8657C137.275 12.4284 135.735 11.2761 134.101 11.2761C132.468 11.2761 130.994 12.4411 130.994 14.8657C130.994 17.2904 132.416 18.4818 134.048 18.4818Z"
        fill="#1D1D1B"
        fillRule="evenodd"
      />
      <path
        d="M141.091 16.9417C141.197 18.8834 142.975 20.3976 145.489 20.3976C148.003 20.3976 149.517 19.0443 149.517 17.2363C149.45 15.0236 147.481 14.4697 145.807 13.9986C144.551 13.6448 143.462 13.3384 143.462 12.4143C143.462 11.6913 144.08 11.2093 145.239 11.2093C146.397 11.2093 147.122 11.8387 147.2 12.7763H149.373C149.256 10.6327 147.688 9.33398 145.306 9.33398C142.923 9.33398 141.264 10.7136 141.264 12.428C141.264 14.6935 143.301 15.2501 145.021 15.7193C146.282 16.0631 147.374 16.3614 147.374 17.25C147.374 17.9866 146.715 18.536 145.451 18.536C144.188 18.536 143.424 17.8129 143.345 16.9426H141.093L141.091 16.9417Z"
        fill="#1D1D1B"
      />
      <path
        d="M30.2849 15.622C30.2849 7.16942 23.5538 0.319336 15.2479 0.319336C6.94201 0.319336 0.241178 7.13851 0.218262 15.5674H5.2306C5.26085 9.95603 9.73326 5.41962 15.2553 5.41962C20.7773 5.41962 25.2799 9.98604 25.2799 15.6211C25.2799 21.2561 20.8231 25.7916 15.3084 25.8226V30.9228C18.2445 30.9147 20.9899 30.0479 23.3027 28.5546L30.2923 30.9228L27.9648 23.8099C29.4324 21.4417 30.2849 18.6323 30.2849 15.622Z"
        fill="#FD6112"
      />
      <path
        d="M75.9678 31.137H76.5865V28.6487H78.6124V28.1267H76.5865V26.1832H78.9864V25.6611H75.9678V31.137Z"
        fill="#1D1D1B"
      />
      <path
        d="M84.8566 25.9556C84.4423 25.7192 83.9721 25.5991 83.4596 25.5991C82.9472 25.5991 82.4843 25.7192 82.07 25.9556C81.6556 26.1921 81.3247 26.5277 81.0864 26.9515C80.848 27.3753 80.7271 27.861 80.7271 28.3957C80.7271 28.9305 80.848 29.4161 81.0864 29.839C81.3247 30.2629 81.6556 30.5975 82.07 30.8349C82.4834 31.0714 82.9509 31.1914 83.4596 31.1914C83.9684 31.1914 84.4432 31.0714 84.8566 30.8349C85.271 30.5985 85.601 30.2629 85.8366 29.839C86.0721 29.4161 86.1913 28.9296 86.1913 28.3957C86.1913 27.8619 86.0721 27.3753 85.8366 26.9524C85.601 26.5286 85.271 26.1939 84.8566 25.9565V25.9556ZM83.4587 30.6457C83.0618 30.6457 82.7016 30.5539 82.3871 30.3729C82.0727 30.1919 81.8206 29.9273 81.6382 29.5871C81.4549 29.2461 81.3623 28.845 81.3623 28.3948C81.3623 27.9446 81.4549 27.5445 81.6382 27.2061C81.8216 26.8687 82.0736 26.6059 82.388 26.4249C82.7025 26.2439 83.0636 26.1521 83.4596 26.1521C83.8556 26.1521 84.2168 26.2439 84.5312 26.4249C84.8456 26.6059 85.0977 26.8687 85.281 27.2061C85.4644 27.5445 85.557 27.9437 85.557 28.3948C85.557 28.8459 85.4644 29.2461 85.281 29.5871C85.0977 29.9273 84.8456 30.191 84.5321 30.3729C84.2177 30.5539 83.8566 30.6457 83.4606 30.6457H83.4587Z"
        fill="#1D1D1B"
      />
      <path
        d="M88.902 25.6611H88.2832V31.137H90.8572V30.6232H88.902V25.6611Z"
        fill="#1D1D1B"
      />
      <path
        d="M96.228 28.0885H93.3497V25.6611H92.731V31.137H93.3497V28.6096H96.228V31.137H96.8477V25.6611H96.228V28.0885Z"
        fill="#1D1D1B"
      />
      <path
        d="M101.479 25.731H100.811L98.7974 31.1368H99.4528L99.9276 29.8536H102.353L102.828 31.1368H103.483L101.486 25.7546L101.477 25.731H101.479ZM102.163 29.3324H100.119L101.141 26.5549L102.163 29.3324Z"
        fill="#1D1D1B"
      />
      <path
        d="M111.954 25.9904C111.52 25.7722 110.993 25.6621 110.388 25.6621H108.738V31.138H110.388C110.993 31.138 111.52 31.0289 111.954 30.8133C112.39 30.5969 112.728 30.2804 112.959 29.8711C113.19 29.4628 113.306 28.9717 113.306 28.4114C113.306 27.8512 113.19 27.351 112.959 26.9408C112.728 26.5288 112.39 26.2096 111.954 25.9904ZM109.357 26.1832H110.388C111.132 26.1832 111.706 26.3815 112.095 26.7717C112.483 27.1618 112.679 27.7139 112.679 28.4114C112.679 29.109 112.482 29.6501 112.095 30.0357C111.707 30.4213 111.132 30.616 110.388 30.616H109.357V26.1841V26.1832Z"
        fill="#1D1D1B"
      />
      <path
        d="M116.025 25.6611H115.406V31.137H116.025V25.6611Z"
        fill="#1D1D1B"
      />
      <path
        d="M121.575 28.2913C121.805 28.0103 121.922 27.6656 121.922 27.2673C121.922 26.7898 121.76 26.3987 121.439 26.104C121.119 25.8103 120.645 25.6611 120.031 25.6611H118.358V31.137H118.978V28.8806H119.925L121.272 31.137H122.014L120.605 28.8306C121.027 28.7424 121.354 28.5614 121.575 28.2913H121.575ZM118.977 26.1914H120.03C120.464 26.1914 120.786 26.2859 120.986 26.4715C121.185 26.657 121.286 26.9244 121.286 27.2673C121.286 27.6101 121.18 27.8702 120.974 28.0676C120.767 28.2658 120.452 28.3659 120.038 28.3659H118.977V26.1904V26.1914Z"
        fill="#1D1D1B"
      />
      <path
        d="M124.718 25.6611H124.098V31.137H124.718V25.6611Z"
        fill="#1D1D1B"
      />
      <path
        d="M129.279 28.7105H131.566C131.536 29.0888 131.433 29.4244 131.259 29.71C131.078 30.0046 130.837 30.2338 130.541 30.3921C130.244 30.5503 129.906 30.6313 129.534 30.6313C129.137 30.6313 128.777 30.5394 128.463 30.3584C128.148 30.1774 127.896 29.9146 127.713 29.5772C127.53 29.2389 127.437 28.8423 127.437 28.3967C127.437 27.9511 127.53 27.5554 127.713 27.2199C127.896 26.8852 128.148 26.6232 128.463 26.4423C128.777 26.2613 129.138 26.1694 129.534 26.1694C129.956 26.1694 130.328 26.2704 130.64 26.4686C130.952 26.6669 131.187 26.9443 131.338 27.2908L131.348 27.3126H132.065L132.046 27.2626C131.846 26.7424 131.523 26.3313 131.082 26.0394C130.641 25.7483 130.121 25.6001 129.534 25.6001C129.027 25.6001 128.559 25.7201 128.145 25.9566C127.73 26.1931 127.399 26.5287 127.161 26.9525C126.923 27.3763 126.802 27.8619 126.802 28.3967C126.802 28.9315 126.923 29.4171 127.161 29.84C127.399 30.2638 127.73 30.5985 128.145 30.8359C128.558 31.0723 129.026 31.1924 129.534 31.1924C130.043 31.1924 130.478 31.0787 130.873 30.855C131.269 30.6313 131.589 30.3211 131.825 29.9337C132.061 29.5463 132.194 29.1079 132.22 28.6277V28.1966H129.28V28.7105H129.279Z"
        fill="#1D1D1B"
      />
      <path
        d="M134.931 25.6611H134.311V31.137H134.931V25.6611Z"
        fill="#1D1D1B"
      />
      <path
        d="M140.479 25.9904C140.045 25.7722 139.518 25.6621 138.913 25.6621H137.263V31.138H138.913C139.518 31.138 140.045 31.0289 140.479 30.8133C140.915 30.5969 141.253 30.2804 141.484 29.8711C141.715 29.4628 141.831 28.9717 141.831 28.4114C141.831 27.8512 141.715 27.351 141.484 26.9408C141.253 26.5288 140.915 26.2096 140.479 25.9904ZM137.882 26.1832H138.913C139.657 26.1832 140.231 26.3815 140.62 26.7717C141.008 27.1618 141.204 27.7139 141.204 28.4114C141.204 29.109 141.007 29.6501 140.62 30.0357C140.232 30.4213 139.657 30.616 138.913 30.616H137.882V26.1841V26.1832Z"
        fill="#1D1D1B"
      />
      <path
        d="M146.23 25.7546L146.221 25.731H145.554L143.54 31.1368H144.195L144.67 29.8536H147.096L147.571 31.1368H148.226L146.229 25.7546H146.23ZM146.906 29.3324H144.862L145.884 26.5549L146.906 29.3324Z"
        fill="#1D1D1B"
      />
    </StyledSvg>
  );
};

const StyledSvg = styled('svg', {});
