import { Box } from '@qcx/ui';
import { Link } from '@qcx/remix';

export const LogoLight = () => (
  <Link
    css={{
      d: 'flex',
      flexRowCenter: true,
      gap: '$2'
    }}
    aria-label="QConcursos.com"
    to={'https://www.qconcursos.com/'}
  >
    <Box>
      <svg
        width="32"
        height="32"
        fill="none"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M31.9919 16C31.9919 7.16237 24.8295 0 15.9919 0C7.17855 0 0.0242792 7.12999 0 15.9433H5.33333C5.36571 10.0759 10.1244 5.33333 16 5.33333C21.8918 5.33333 26.6667 10.1082 26.6667 16C26.6667 21.8756 21.9241 26.6343 16.0567 26.6667V26.6829V32C19.1806 31.9919 22.1022 31.0855 24.5625 29.5235L32 32L29.5235 24.5625C31.0855 22.086 31.9919 19.1482 31.9919 16Z"
          fill="white"
        />
      </svg>
    </Box>
    <Box
      css={{
        '@xs': { display: 'none' },
        '@lg': { display: 'block' }
      }}
    >
      <svg
        width="90"
        height="13"
        fill="none"
        viewBox="0 0 90 13"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          clipRule="evenodd"
          d="M10.9491 12.6029L8.41591 9.91277C9.86043 8.99162 10.792 7.37962 10.7816 5.39079C10.7816 2.22959 8.39497 0 5.39079 0C2.418 0 0 2.22959 0 5.39079C0 8.56245 2.418 10.792 5.39079 10.792C5.92463 10.792 6.42708 10.7292 6.89811 10.5932L8.75087 12.6029H10.9491ZM9.01256 5.39079C9.01256 7.77739 7.49477 9.29518 5.40126 9.29518C3.30775 9.29518 1.78995 7.77739 1.78995 5.39079C1.78995 3.00418 3.30775 1.51779 5.40126 1.51779C7.49477 1.51779 9.01256 3.00418 9.01256 5.39079Z"
          fill="white"
          fillRule="evenodd"
        />
        <path
          d="M19.9093 4.96161C19.4383 3.20306 18.1089 2.18771 16.0572 2.18771C13.6602 2.18771 11.954 3.88345 11.954 6.50034C11.954 9.12769 13.6602 10.8234 16.0572 10.8234C18.0984 10.8234 19.4383 9.69294 19.9093 8.04953H18.0461C17.732 8.90787 17.0935 9.36844 16.0572 9.36844C14.6546 9.36844 13.7334 8.33216 13.7334 6.50034C13.7334 4.67898 14.6651 3.64269 16.0572 3.64269C17.0935 3.64269 17.753 4.15561 18.0461 4.96161H19.9093Z"
          fill="white"
        />
        <path
          clipRule="evenodd"
          d="M25.2686 10.8234C22.882 10.8234 21.0816 9.12769 21.0816 6.50034C21.0816 3.87298 22.9658 2.18771 25.3419 2.18771C27.7285 2.18771 29.6022 3.87298 29.6022 6.50034C29.6022 9.12769 27.6657 10.8234 25.2686 10.8234ZM25.2686 9.32657C26.5562 9.32657 27.8332 8.40543 27.8332 6.50034C27.8332 4.59524 26.6085 3.69503 25.3105 3.69503C24.0021 3.69503 22.8402 4.60571 22.8402 6.50034C22.8402 8.39496 23.9707 9.32657 25.2686 9.32657Z"
          fill="white"
          fillRule="evenodd"
        />
        <path
          d="M35.171 3.68456C36.4271 3.68456 37.2645 4.4801 37.2645 6.00836V10.6874H38.9812V5.75714C38.9812 3.41241 37.5052 2.18771 35.5478 2.18771C34.5429 2.18771 33.6218 2.59594 33.0565 3.27633V2.32378H31.3294V10.6874H33.0565V6.00836C33.0565 4.46963 33.8939 3.68456 35.171 3.68456Z"
          fill="white"
        />
        <path
          d="M44.6965 2.18771C46.7481 2.18771 48.0775 3.20306 48.5485 4.96161H46.6853C46.3922 4.15561 45.7327 3.64269 44.6965 3.64269C43.3043 3.64269 42.3727 4.67898 42.3727 6.50034C42.3727 8.33216 43.2938 9.36844 44.6965 9.36844C45.7327 9.36844 46.3713 8.90787 46.6853 8.04953H48.5485C48.0775 9.69294 46.7376 10.8234 44.6965 10.8234C42.2994 10.8234 40.5932 9.12769 40.5932 6.50034C40.6036 3.88345 42.2994 2.18771 44.6965 2.18771Z"
          fill="white"
        />
        <path
          d="M56.127 10.6874H57.8646V2.32381H56.1375V6.9714C56.1375 8.51013 55.3001 9.29519 54.023 9.29519C52.7669 9.29519 51.9295 8.49966 51.9295 6.9714V2.31334H50.2128V7.23309C50.2128 9.57782 51.6992 10.8025 53.6567 10.8025C54.6406 10.8025 55.5827 10.3943 56.127 9.68249V10.6874Z"
          fill="white"
        />
        <path
          d="M61.8737 10.6874H60.1465V2.32378H61.8737V3.53802C62.3552 2.70061 63.1926 2.18771 64.3754 2.18771V3.97765H63.9358C62.6587 3.97765 61.8737 4.5115 61.8737 6.28051V10.6874Z"
          fill="white"
        />
        <path
          d="M65.5059 8.1228C65.5897 9.64059 67.0028 10.8234 69.0021 10.8234C70.9805 10.8234 72.2052 9.7662 72.2052 8.35308C72.1513 6.62406 70.5861 6.19102 69.2547 5.82269C68.2567 5.54657 67.3901 5.30682 67.3901 4.58476C67.3901 4.01951 67.8821 3.64268 68.8032 3.64268C69.7453 3.64268 70.3001 4.13466 70.3629 4.86739H72.09C71.9958 3.19258 70.7502 2.17723 68.8555 2.17723C66.8876 2.17723 65.642 3.25539 65.642 4.59523C65.642 6.366 67.2611 6.80035 68.6296 7.1675C69.6314 7.43625 70.499 7.66899 70.499 8.36355C70.499 8.93926 69.9756 9.36843 68.9707 9.36843C68.0077 9.36843 67.3587 8.80319 67.2959 8.1228H65.5059Z"
          fill="white"
        />
        <path
          clipRule="evenodd"
          d="M77.7006 10.8234C75.314 10.8234 73.5136 9.12769 73.5136 6.50034C73.5136 3.87298 75.3978 2.18771 77.7739 2.18771C80.1605 2.18771 82.0342 3.87298 82.0342 6.50034C82.0342 9.12769 80.0977 10.8234 77.7006 10.8234ZM77.7006 9.32657C78.9881 9.32657 80.2652 8.40543 80.2652 6.50034C80.2652 4.59524 79.0405 3.69503 77.7425 3.69503C76.434 3.69503 75.2721 4.60571 75.2721 6.50034C75.2721 8.39496 76.4026 9.32657 77.7006 9.32657Z"
          fill="white"
          fillRule="evenodd"
        />
        <path
          d="M83.3008 8.1228C83.3845 9.64059 84.7976 10.8234 86.7969 10.8234C88.7648 10.8234 90 9.7662 90 8.35308C89.9461 6.62406 88.3809 6.19102 87.0495 5.82269C86.0515 5.54657 85.1849 5.30682 85.1849 4.58476C85.1849 4.01951 85.6769 3.64268 86.598 3.64268C87.5401 3.64268 88.0949 4.13466 88.1577 4.86739H89.8848C89.7906 3.19258 88.545 2.17723 86.6504 2.17723C84.6825 2.17723 83.4368 3.25539 83.4368 4.59523C83.4368 6.366 85.0559 6.80035 86.4244 7.1675C87.4262 7.43625 88.2938 7.66899 88.2938 8.36355C88.2938 8.93926 87.7704 9.36843 86.7655 9.36843C85.8025 9.36843 85.1535 8.80319 85.0907 8.1228H83.3008Z"
          fill="white"
        />
      </svg>
    </Box>
  </Link>
);
