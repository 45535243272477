import { Box, Button, Icon, Input } from '@qcx/ui';
import { Form, useNavigate } from '@remix-run/react';
import { FormEvent, useCallback, useState } from 'react';
import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import { route } from '../../../route';

export const SearchBar = ({ ...props }) => {
  const [term, setTerm] = useState('');
  const navigate = useNavigate();
  const handleSubmit = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      navigate(
        route('search', {
          qs: {
            q: term
          }
        })
      );
    },
    [navigate, term]
  );
  return (
    <Box {...props}>
      <Form action={route('search')} method="post" onSubmit={handleSubmit}>
        <Input
          name="q"
          css={{
            '> div': {
              border: '1px solid $neutral100 !important',
              bgColor: '$white !important'
            },
            input: {
              '&::placeholder': {
                color: '$neutral500'
              },
              '&:autofill, &:autofill:focus, &:-webkit-autofill, &:-webkit-autofill:focus, &:-internal-autofill-selected':
                {
                  bgColor: '$neutral700',
                  colorScheme: 'dark'
                }
            }
          }}
          placeholder="Buscar por notícias"
          suffix={
            <Button
              css={{
                color: term.length < 3 ? '$textMuted' : '$neutral800',
                pointerEvents: term.length < 3 ? 'none' : 'auto'
              }}
              aria-label="Buscar por notícias"
              icon={<Icon icon={faSearch} />}
              type="submit"
              variant="neutralLink"
              iconButton
            />
          }
          value={term}
          onChange={({ target: { value } }) => setTerm(value ?? '')}
        />
      </Form>
    </Box>
  );
};
