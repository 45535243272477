import modalBannerSrc from '../../assets/banners/auth_banner.webp';
import { ApiError } from '@atreus/data';
import { Banner } from '../../pages/homepage/components/interventions/HomeBanner';
import { createStore } from '@qcx/store';
import { route } from '../../route';

export interface AtreusAuthModalStoreState {
  banner?: Banner;
}

const initialState: AtreusAuthModalStoreState = {
  banner: {} as Banner
};

const { Provider, useStore } = createStore(initialState, ({ set }) => {
  const fetchBanner = async () => {
    try {
      const response = await fetch(route('api-banner'), {
        method: 'GET'
      });

      if (!response.ok) {
        throw new Error('Falha ao carregar banner');
      }
      const bannerData: Banner = await response.json();

      set({
        banner: bannerData
      });
    } catch (error) {
      if (error instanceof ApiError && error.status === 401) {
        set({
          banner: undefined
        });
      }
    }
  };

  return { fetchBanner };
});

export const useLoginBanner = () => {
  const loginBanner = useAtreusAuthModalStore(s => s.banner);

  let imageUrl = modalBannerSrc;
  let link = '#';
  let alt = 'Banner de login';

  if (loginBanner?.status === 'published') {
    imageUrl = `${global.ENV.PUBLIC_NEXUS_ASSETS_URL}/${loginBanner.xsImage.filename_disk}`;
    link = loginBanner.link;
    alt = loginBanner.alt;
  }

  return { imageUrl, link, alt };
};

export const AtreusAuthModalStoreProvider = Provider;
export const useAtreusAuthModalStore = useStore;
