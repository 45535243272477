import QcIcon from '../../../assets/icons/qc/logo.svg';
import { AtreusAuthModalStoreProvider } from '../../AtreusAuthModal/atreus-auth-modal.store';
import {
  Box,
  Button,
  Collapse,
  CollapseContent,
  CollapseTrigger,
  Container,
  Icon,
  Image,
  Stack,
  Text,
  styled
} from '@qcx/ui';
import { FOLHA_PLATFORM_URL } from '../../../lib/constants/url.lib';
import { Link } from '@qcx/remix';
import { Logo } from '../Logo';
import {
  MenuItem,
  useIsUserAuthenticated,
  useShellStore
} from '../shell.store';
import { SearchBar } from './SearchBar';
import { Suspense, lazy, useCallback, useState } from 'react';
import { faBars, faSearch, faTimes } from '@fortawesome/pro-regular-svg-icons';

const NavMenu = lazy(() =>
  import('../NavMenu').then(m => ({ default: m.NavMenu }))
);
const HeaderUser = lazy(() =>
  import('./HeaderUser').then(m => ({ default: m.HeaderUser }))
);
const AtreusAuthModal = lazy(() =>
  import('../../AtreusAuthModal').then(m => ({ default: m.AtreusAuthModal }))
);

export const Header = () => {
  const isUserAuthenticated = useIsUserAuthenticated();

  const fdoHeader = {
    active: true,
    items: [
      {
        title: 'Questões',
        href: 'https://www.qconcursos.com/questoes-de-concursos/questoes',
        event: 'fdo header questions clicked',
        props: {
          target: '_blank'
        }
      },
      {
        title: 'Guias de estudos',
        href: 'https://www.qconcursos.com/questoes-de-concursos/guias/organize-seus-estudos',
        event: 'fdo header study guide clicked',
        props: {
          target: '_blank'
        }
      },
      {
        title: 'Provas',
        href: 'https://www.qconcursos.com/questoes-de-concursos/provas',
        event: 'fdo header tests clicked',
        props: {
          target: '_blank'
        }
      }
    ],
    cta: {
      title: 'Estude grátis',
      href: 'https://www.qconcursos.com/conta/cadastrar',
      event: 'fdo header register clicked',
      props: {
        target: '_blank'
      }
    }
  };

  const [open, setOpen] = useState(false);

  const toggleMenu = useShellStore(s => s.toggleMenu);

  const handleClose = useCallback(() => {
    toggleMenu();
  }, [toggleMenu]);

  return (
    <Box css={{ bgColor: '$white' }} className="dark">
      <Collapse open={open} onOpenChange={setOpen}>
        <Container css={{ py: '$4', flexRowCenter: true }}>
          <Stack
            css={{
              flex: 1,
              justifyContent: 'space-between',
              '@lg': {
                justifyContent: 'flex-start',
                gap: '$8'
              },
              flexRowCenter: true
            }}
            flow="row"
          >
            <Button
              css={{
                color: '$neutral700',
                '@xl': {
                  d: 'none'
                }
              }}
              aria-label="Menu"
              leftIcon={
                <Icon
                  css={{ minMaxH: 44, color: '$neutral700' }}
                  icon={faBars}
                />
              }
              size="sm"
              variant="neutralLink"
              onClick={handleClose}
            >
              Menu
            </Button>
            <Button
              as="a"
              css={{
                flexRowCenter: true,
                '@lg': {
                  order: 0,
                  pl: 0
                }
              }}
              href={FOLHA_PLATFORM_URL}
              title="Qconcursos.com"
              variant="neutralFaded"
            >
              <Logo />
            </Button>
            <SearchBar
              css={{
                w: '$full',
                maxW: 360,
                '> div': {
                  minMaxH: 36
                },
                input: {
                  bgColor: '$neutral50',
                  color: '$neutral800',
                  textSm: true
                },
                span: {
                  bgColor: '$neutral50'
                },
                d: 'none',
                '@xs': {
                  order: 3
                },
                '@sm': {
                  order: 3
                },
                '@lg': {
                  d: 'flex'
                }
              }}
            />
            <CollapseTrigger>
              <Button
                css={{
                  '@lg': {
                    d: 'none'
                  }
                }}
                aria-label="Buscar"
                icon={
                  <Icon
                    css={{ minMaxW: 59, color: '$neutral700' }}
                    icon={open ? faTimes : faSearch}
                  />
                }
                variant="neutralLink"
                iconButton
              />
            </CollapseTrigger>
          </Stack>
          {fdoHeader.active && (
            <Stack
              as="nav"
              css={{
                flexCenter: true,
                bgColor: '$neutral50',
                rounded: '$4xl',
                py: '$2',
                px: '$3',
                d: 'none',
                '@lg': {
                  d: 'flex'
                }
              }}
              flow="row"
              gap="$6"
            >
              <Link target="_blank" to="https://www.qconcursos.com/">
                <StyledIcon
                  width={24}
                  height={24}
                  alt="Logo Qconcursos"
                  src={QcIcon}
                  noAspectRatio
                  priority
                />
              </Link>
              {fdoHeader.items.map((menu: MenuItem, idx: number) => (
                <Link
                  key={`menu_${idx}`}
                  css={{
                    color: '$neutral700',
                    d: 'none',
                    '@xl': {
                      d: 'flex'
                    },
                    '&:hover': {
                      color: '$neutral700',
                      textDecoration: 'underline'
                    }
                  }}
                  title={`Qconcursos: ${menu.title}`}
                  to={menu.href}
                  {...menu.props}
                  onClick={() => menu.event}
                >
                  <Text
                    css={{ color: 'CurrentColor' }}
                    variant="sm"
                    weight="medium"
                  >
                    {menu.title}
                  </Text>
                </Link>
              ))}
              <StyledButtonSignIn
                css={{
                  bgColor: '#FE6112',
                  '&:hover': {
                    bgColor: '#FE7F40'
                  }
                }}
                tabIndex={0}
                to={fdoHeader.cta.href}
                {...fdoHeader.cta.props}
                onClick={() => fdoHeader.cta.event}
              >
                {fdoHeader.cta.title}
              </StyledButtonSignIn>
            </Stack>
          )}
          {isUserAuthenticated ? (
            <Suspense>
              <HeaderUser />
            </Suspense>
          ) : (
            <Suspense>
              <AtreusAuthModalStoreProvider>
                <AtreusAuthModal />
              </AtreusAuthModalStoreProvider>
            </Suspense>
          )}
        </Container>
        <Suspense>
          <NavMenu />
        </Suspense>
        <CollapseContent>
          <SearchBar
            css={{
              w: '$full',
              label: { d: 'flex' },
              input: {
                color: '$neutral700',
                textSm: true,
                borderRadius: 0,
                '&::placeholder': {
                  color: '$neutral500'
                }
              },
              div: {
                m: '$4'
              }
            }}
          />
        </CollapseContent>
      </Collapse>
    </Box>
  );
};

const StyledIcon = styled(Image, {
  ml: '$1',
  '& img': {
    h: 24,
    w: 24,
    objectFit: 'contain'
  }
});

const StyledButtonSignIn = styled(Link, {
  bgColor: '$secondary500',
  fontWeight: '$bold',
  fontSize: '$sm',
  minW: '$9',
  minH: '$9',
  h: '$9',
  rounded: '$lg',
  p: '$3dot5',
  lineHeight: '$sm ',
  d: 'flex',
  flexCenter: true,
  color: '$hiContrast',
  '&:hover, &:active': {
    bgColor: '#FE6112',
    color: '$hiContrast'
  }
});
