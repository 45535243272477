import {
  Box,
  Button,
  Container,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  Icon,
  Stack
} from '@qcx/ui';
import { Link } from '@qcx/remix';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { useShellStore } from './shell.store';

export const Navbar = () => {
  const menuItems = useShellStore(s => s.menuItems);

  return (
    <Box
      css={{
        d: 'none',
        shadow: '$xs',
        bt: '1px solid $separatorColor',
        bb: '1px solid $separatorColor',
        '@lg': {
          d: 'flex'
        }
      }}
    >
      <Container
        css={{
          flexRowCenter: true,
          py: '$1',
          divideX: '1px solid $separatorColor'
        }}
      >
        <Stack css={{ flex: 1 }} gap="$10">
          {menuItems.map(item =>
            item.children ? (
              <DropdownMenu key={item.id}>
                <DropdownMenuTrigger>
                  <Button
                    css={{
                      minW: 120,
                      justifyContent: 'center',
                      py: '$5 !important'
                    }}
                    aria-label="Menu Dropdown"
                    rightIcon={<Icon icon={faChevronDown} size="sm" />}
                    variant="neutralLink"
                  >
                    {item.title}
                  </Button>
                </DropdownMenuTrigger>
                {item.children && (
                  <DropdownMenuContent align="start">
                    {item.children.map(sub => (
                      <DropdownMenuItem key={sub.id} asChild>
                        <Link
                          css={{ textSm: true }}
                          title={sub.title}
                          to={sub.href!}
                        >
                          {sub.title}
                        </Link>
                      </DropdownMenuItem>
                    ))}
                  </DropdownMenuContent>
                )}
              </DropdownMenu>
            ) : (
              <Button
                key={item.title}
                as={Link}
                css={{
                  minW: 120,
                  justifyContent: 'center',
                  py: '$5 !important'
                }}
                aria-label={item.title}
                target={item.props?.target}
                to={item.href!}
                variant="neutralLink"
              >
                {item.title}
              </Button>
            )
          )}
        </Stack>
      </Container>
    </Box>
  );
};
